<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
         
            <el-breadcrumb-item>信息管理</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col
          :span="2"
          class="add-btn-container"
        >
          <el-button
            v-if="itype==1"
            type="text"
            @click="toCreate"
          >添加</el-button>
        </el-col>
      </el-row>
    </div>

    <el-container>
      <!--分类-->
      <el-aside width="200px">
        <el-tree
          :data="treedata"
          ref="tree"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </el-aside>
      <el-main>

        <div
          class="main"
          v-if="itype==2"
        >

          <el-form
            class="form-container"
            ref="form"
            :model="form"
            label-width="140px"
          >
            <el-form-item label="序号">
              <el-input v-model.number="form.sort"></el-input>
            </el-form-item>
            <el-form-item
              label="所属分类"
              :error="$v.form.pId.$error ? '请输入选择所属分类' : ''"
            >
              <el-cascader
                :options="classify"
                :props="{ checkStrictly: true }"
                clearable
                v-model="form.pId"
                @change="changeClassify"
              ></el-cascader>
            </el-form-item>
            <el-form-item
              label="分类名称"
              :error="$v.form.name.$error ? '请输入分类名称' : ''"
            >
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="缩略图">
              <image-upload
                @upload="imageUpload"
                :image="form.img"
              />
            </el-form-item>
            <el-form-item label="关键字">
              <el-input v-model="form.keyWord"></el-input>
            </el-form-item>
            <el-form-item label="标签">
              <el-input v-model="form.tag"></el-input>
            </el-form-item>
            <el-form-item
              label="描述"
              :error="$v.form.describe.$error ? '请输入描述' : ''"
            >
              <el-input v-model="form.describe"></el-input>
            </el-form-item>

            <el-form-item label="类型">
              <el-radio-group v-model="form.iType">
                <el-radio :label=1>列表</el-radio>
                <el-radio :label=2>单页</el-radio>
                <el-radio :label=3>列表</el-radio>
              </el-radio-group>

            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group v-model="form.states">
                <el-radio :label=1>启用</el-radio>
                <el-radio :label=0>禁用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="block-item"
              label="详情"
            >
              <tinymce v-model="form.content" />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="save"
              >保存</el-button>

            </el-form-item>
          </el-form>

        </div>
        <!-- 列表内容 -->
        <div
          class="main"
          v-if="itype==1"
        >
          <el-form
            :inline="true"
            class="demo-form-inline"
          >
            <el-form-item>
              <el-input
                v-model="title"
                placeholder="请输入标题"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="getList"
              >查询</el-button>
            </el-form-item>
          </el-form>
          <el-table
            :data="listData"
            border=""
            style="width: 100%"
          >
            <el-table-column
              prop="sorder"
              width="95"
              label="序号"
            >
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.sorder"
                  @change="updatesort(scope.row)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="图片"
              width="110"
              align="center"
            >
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.thumbnailImg"
                  alt="图片"
                >
                  <div
                    slot="error"
                    class="image-slot"
                  >
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="title"
              label="标题"
            ></el-table-column>
            <el-table-column
              prop="keyWord"
              label="关键字"  width="200"
            ></el-table-column>
            <el-table-column
              prop="describe"
              label="描述"
            ></el-table-column>
            <el-table-column
              prop="addDate"
              label="添加日期"
            ></el-table-column>
            <el-table-column
              prop="visitsNum"
              label="访问量"
            ></el-table-column>
            <el-table-column
              prop="virtualTraffic"
              label="虚拟访问量"
            ></el-table-column>
            <el-table-column
              prop="states"
              label="状态"
              width="60"
            >
              <template slot-scope="scope"> {{['禁用','启用'][scope.row.states]}} </template>
            </el-table-column>
            <el-table-column
              label="操作"
              fixed="right"
              width="213"
            >
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  @click="setStates(scope.row.id, 0, scope.row)"
                  v-if="scope.row.states == 1"
                >禁用</el-button>
                <el-button
                  type="success"
                  size="mini"
                  @click="setStates(scope.row.id, 1, scope.row)"
                  v-else
                >启用</el-button>
                <el-button
                  size="mini"
                  @click="toEdit(scope.row.id)"
                >编辑</el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click="onDelete(scope.row.id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div
          class="pagination"
          v-if="itype==1"
        >
          <el-pagination
            background=""
            layout="prev, pager, next"
            :page-size="10"
            :current-page="pageIndex"
            :total="pageTotal"
            @current-change="onCurrentChange"
          />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Tinymce from "@/components/tinymce";
import ImageUpload from "@/components/imageUpload";
import {
  getListRequest,
  setSortRequest,
  setStatesRequest,
  delarticleRequest
} from "@/api/article";
import {
  edittypeRequest,
  getSelectRequest,
  getdatilRequest
} from "@/api/classify";

import { required } from "vuelidate/lib/validators";
export default {
  name: "ArticleList",
  data() {
    return {
      listData: [],
      classify: [],
      title: "",
      typeId: "",
      pageIndex: 1,
      pageTotal: 0,
      treedata: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      itype: 1,
      form: {
        id: 0,
        img: "",
        pId: "",
        name: "",
        keyWord: "",
        tag: "",
        describe: "",
        states: 1,
        addDate: "",
        sort: 0,
        iType: 1,
        content: ""
      }
    };
  },
  created() {
    this.getList();
    this.getSelect();
  },
  components: {
    "image-upload": ImageUpload,
    tinymce: Tinymce
  },

  watch: {
    $route: {
      handler(to, from) {
        this.itype = to.query.itype||1;
        this.typeId = to.query.typeId;

        if (this.itype == 1) {
          this.onCurrentChange(1);
        } else if (this.itype == 2) {
          this.gettypedetail();
        }
      },
      immediate: true
    }
  },

  methods: {
    imageUpload(url) {
      this.form.img = url;
    },
    save() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        edittypeRequest(this.form).then(res => {
          if (res.code == 1) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    getList() {
      getListRequest({
        Title: this.title,
        ArticleTypeId: this.typeId,
        pageNum: this.pageIndex,
        pageSize: 10
      }).then(res => {
        this.listData = res.data.data;
        this.pageTotal = res.data.count;
      });
    },
    getSelect() {
      getSelectRequest().then(res => {
        this.classify = res.data;
        this.treedata = res.data;
      });
    },
    gettypedetail() {
      getdatilRequest({
        Id: this.typeId
      }).then(res => {
        console.log(res);
        this.form = res.data;
      });
    },
    changeClassify(e) {
      this.typeId = e[e.length - 1];
    },
    handleNodeClick(data) {
      this.$router.push({
        path: "ArticleList",
        query: { itype: data.iType, typeId: data.value }
      });
    },
    updatesort(row) {
      setSortRequest({
        Id: row.id,
        Sorder: row.sorder
      }).then(res => {});
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states
      }).then(res => {
        row.states = states;
      });
    },
    toCreate() {
      this.$router.push({
        path: "ArticleList/create",
        query: { articleTypeId: this.articleTypeId }
      });
    },
    toEdit(id) {
      this.$router.push(`/cmsmange/ArticleList/edit/${id}`);
    },
    onDelete(id) {
      this.$confirm("确认删除该文章吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 调用删除接口
          return delarticleRequest({ id });
        })
        .then(() => {
          this.$message({
            type: "success",
            message: `删除成功！`
          });
          this.getList();
        });
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      pId: {
        required
      },
      describe: {
        required
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
